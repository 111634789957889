<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="12">
        <span class="text-subtitle-1 secondary--text font-weight-bold">Minimum Salary Check</span>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" sm="2">
        <text-display :displayLabel="'Minimum Salary'"
            class="text-center"
            :displayString="this.$_formatMoney(minSalary)"
            :displayBold="true" />
      </v-col>

      <v-col cols="12" sm="2">
        <v-select
          label="Total Number of Weeks"
          :items="[43,44,52]"
          placeholder=" "
          persistent-placeholder
          :displayBold="true"
          v-model="numWeeks"
         >
         <template v-slot:selection="{ item }">
          <span class="d-flex justify-center" style="width:100%;">
            {{ item }}
          </span>
        </template>
         </v-select>
      </v-col>

      <v-col cols="12" sm="2">
        <text-display :displayLabel="'Hourly Salary'"
          class="text-center"
          :displayString="this.$_formatMoney(hourlyRate)"
          :displayBold="true" />
      </v-col>

      <v-col cols="12" sm="2">
        <text-display
          :displayLabel="'Bi-Weekly Rate'"
          class="text-center"
          :displayString="this.$_formatMoney(biweeklyRate)"
          :displayBold="true" />
      </v-col>

      <v-col cols="12" sm="2">
        <text-display
          :displayLabel="'Semi-Monthly Rate'"
          class="text-center"
          :displayString="this.$_formatMoney(semiMonthlyRate)"
          :displayBold="true" />
      </v-col>

      <v-col cols="12" sm="2">
        <text-display
          :displayLabel="'Monthly Rate'"
          class="text-center"
          :displayString="this.$_formatMoney(monthlyRate)"
          :displayBold="true" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from "vuex-map-fields";
import { mixin } from '@/mixins/mixin.js';
import TextDisplay from '@/components/TextDisplay';

const { mapFields } = createHelpers({
  getterType: "teacherSalaryStore/getCalcField",
  mutationType: "teacherSalaryStore/updateCalcField"
});

export default {
  name: 'SalaryCheck',
  components: {
    TextDisplay,
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      teacherSalaryStore: state => state.teacherSalaryStore,
    }),
    ...mapFields(["prevTeacherId","minSalary","numWeeks"])
  },
  created() {
    this.initialize();
  },
  watch: {
    numWeeks() {
      this.rateCalculation();
    }
  },
  data() {
    return {
      hourlyRate: 0,
      biweeklyRate: 0,
      semiMonthlyRate: 0,
      monthlyRate: 0,
      rules: {
        only_dollarDigits: [
          v =>
            !v || /^\d+(?:\.\d{1,2})?$/.test(v) && /^[^,]+$/.test(v) || "Enter Dollar Amount - Digits and Decimal Only"
        ]
      }
    };
  },
  methods: {
	  async initialize() {
      const teacher = this.$store.getters["teachersStore/getTeacher"];
      const data = {
        fk_teacherID: teacher.id,
      };
      await this.$store.dispatch('teacherSalaryStore/fetch', data);
      const salary = this.$store.getters["teacherSalaryStore/getTeacherSalary"];
      //Set default minimum salary
      this.minSalary = salary.length > 0 ? salary[0].d_minSalary : 0;
      //Set default number of weeks
      let default_weeks = 44;
      if(this.prevTeacherId !== teacher.id) {
        //when switching from one teacher record to another
        this.numWeeks = salary && salary.length > 0 ? default_weeks : 0;
      } else if (this.numWeeks) {
        //when updating salaries and returning to same teacher record
        this.numWeeks = salary && salary.length > 0 ? this.numWeeks : 0;
      }
      this.rateCalculation();
      //set current teacher id for later comparison
      this.prevTeacherId = teacher.id;
    },

    rateCalculation() {
      let weeks = this.numWeeks;
      let minSalary = this.minSalary;

      if (weeks > 0 && minSalary > 0){
        let hourly = minSalary/ weeks / 40;
        this.hourlyRate = hourly;
        this.biweeklyRate = hourly * 80;
        if (weeks < 52) {
          this.semiMonthlyRate = minSalary / 20;
          this.monthlyRate = minSalary / 10;
        } else {
          this.semiMonthlyRate = minSalary / 24;
          this.monthlyRate = minSalary / 12;
        }
      }
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
