<template>
  <div>

    <!--Table-->
    <v-simple-table
      v-if="teacherSalaryStore.teacherSalary && teacherSalaryStore.teacherSalary.length > 0"
      :fixed-header="true"
      class="py-0 my-0 blue-grey lighten-5"
    >
      <template #default>
        <thead>
          <tr>
            <th class="text-left grey--text text--darken-1 blue-grey lighten-5">School Year</th>
            <th class="text-center grey--text text--darken-1 blue-grey lighten-5">Month</th>
            <th class="text-center grey--text text--darken-1 blue-grey lighten-5">Pay Type</th>
            <th class="text-center grey--text text--darken-1 blue-grey lighten-5">Pay Rate</th>
            <th class="text-center grey--text text--darken-1 blue-grey lighten-5">Minimum Salary</th>
            <th class="text-center grey--text text--darken-1 blue-grey lighten-5">Adequate?</th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in teacherSalaryStore.teacherSalary" :key="item.id">
          <tr v-bind:style="index > 0 ? 'border-top: solid thin #dae1e7;' : ''">
            <td style="border:none" class="text-left" :class="'light-blue--text text--darken-4'">{{ item.d_schoolYear }}</td>
            <td style="border:none" class="text-center" :class="'light-blue--text text--darken-4'">{{ $_monthNumToText(item.f_month) }}</td>
            <td style="border:none" class="text-center" :class="'light-blue--text text--darken-4'">{{ item.d_incomePeriod }}</td>
            <td style="border:none" class="text-center" :class="'light-blue--text text--darken-4'">{{ $_formatMoney(item.d_income) }}</td>
            <td style="border:none" class="text-center" :class="'light-blue--text text--darken-4'">{{ $_formatMoney(item.d_minSalary) }}</td>
            <td style="border:none" class="text-center" :class="item.f_adequate === 1 || item.f_adequate === true ?
                'light-blue--text text--darken-4' : 'error--text font-weight-bold'">{{ $_yesNo(item.f_adequate) }}</td>
          </tr>
          <tr>
            <td colspan="6" class="text-left grey--text text--darken-1 blue-grey lighten-5" style="border:none">
              <strong>Notes: </strong>
              <span class="light-blue--text text--darken-4">{{ item.d_note }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="6" style="vertical-align: text-top; height:5px"><span class="text-caption grey--text"
                >Created: {{ $_formatTimestamp(item.created_at) }} &#8226;
                Modified: {{ $_formatTimestamp(item.updated_at) }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-list v-else class="py-0 blue-grey lighten-5">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="error--text text-subtitle-2 text-xs-center">No Salary Records
            Exist</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';

export default {
  name: 'TeacherSalaryList',
  mixins: [mixin],
  computed: {
    ...mapState({
      teacherSalaryStore: (state) => state.teacherSalaryStore,
      teachersStore: (state) => state.teachersStore,
    }),
  },
  data() {
    return {

    };
  },
  methods: {},
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
