<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="teachersStore.teacher.d_nameLast + ', ' +
      teachersStore.teacher.d_nameFirst" />

      <!--Function Bar-->
      <teacher-function-bar />

      <v-container fluid class="pa-0">
        <v-row dense>

           <v-col cols="12" sm="12" md="9">
             <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-envelope-open-dollar</v-icon>
              <v-toolbar-title class="pl-2">Salary</v-toolbar-title>

              <v-spacer />
                <teacher-salary-entry />
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5">
              <v-card-text>
                 <salary-check/>

                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-divider></v-divider>

                <!--<v-row dense>
                  <v-col cols="12" sm="12">
                    <span class="text-h6 secondary--text font-weight-bold">Salary History</span>
                    <teacher-salary-list :key="teachersStore.teacher.id"/>
                  </v-col>
                </v-row>-->

                <v-row dense>
                  <v-col cols="12" sm="12">
                    <span class="text-subtitle-1 secondary--text font-weight-bold">Salary History</span>
                    <teacher-salary-list :key="teachersStore.teacher.id"/>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="3">
            <notes :key="teachersStore.teacher.id" :noteSection="6" />
          </v-col>

        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import TeacherFunctionBar from '@/components/TeacherFunctionBar';
import Notes from '@/components/Notes';
import TextDisplay from '@/components/TextDisplay';
import TeacherSalaryList from '@/components/TeacherSalaryList';
import TeacherSalaryEntry from '@/components/TeacherSalaryEntry';
import SalaryCheck from '@/components/SalaryCheck';



export default {
  name: 'TeacherSalary',
  components: {
	  TitleBar,
    TeacherFunctionBar,
    Notes,
    TextDisplay,
    TeacherSalaryList,
    TeacherSalaryEntry,
    SalaryCheck,
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      teacherSalaryStore: (state) => state.teacherSalaryStore,
      teachersStore: (state) => state.teachersStore,
      progressStore: (state) => state.progressStore,
      valueListsStore: (state) => state.valueListsStore,
    })
  },

  created() {
    this.initialize();
  },

  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('teachersStore/read', this.$route.params.teacherUUID);

      const teacher = this.$store.getters['teachersStore/getTeacher'];
      const data = {
        fk_teacherID: teacher.id,
      };
      await this.$store.dispatch('teacherSalaryStore/fetch', data);

      this.$store.dispatch('progressStore/set', false);
    }
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
