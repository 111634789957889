<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn v-on="on" rounded outlined @click="openEntry()">
          <v-icon small left>fal fa-pencil-alt</v-icon>Edit
        </v-btn>
      </template>
      <span>Edit Salary</span>
    </v-tooltip>

    <!--Salary Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1480">
      <v-card flat class="white" height="600">
        <v-card-title class="text-h5 secondary--text"
          >Teacher Salary<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card flat class="blue-grey lighten-5 mx-auto" style="width:97%">
          <v-card-text>
              <salary-check/>
          </v-card-text>
          <v-divider />
          <v-divider />
          <v-divider />
        </v-card>
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <v-spacer />
                <v-btn rounded dark color="green lighten-1" class="mb-2" @click="addItem()">
                  <v-icon small left>fal fa-plus</v-icon>New</v-btn
                >
                <v-col cols="12" sm="12">
                  <v-card flat :key="index" v-for="(item, index) in items">
                    <v-container v-if="item.f_show" fluid class="pa-0">
                      <v-row dense>
                        <!--School Year-->
                        <v-col cols="12" sm="12" md="2">
                          <v-text-field
                            v-model="item.d_schoolYear"
                            label="School Year"
                            placeholder=" "
                            persistent-placeholder
                            v-on:keyup="item.d_schoolYear = yearString(item.d_schoolYear)"
                          />
                        </v-col>

                        <!--Check-In Month-->
                        <v-col cols="12" sm="12" md="2">
                          <v-autocomplete
                            :items="valueListsStore.months"
                            item-text="value"
                            item-value="id"
                            v-model="item.f_month"
                            label="Check-in Month"
                            ref="checkIn"
                            placeholder=" "
                            persistent-placeholder
                            clearable
                            clear-icon="fal fa-times-circle"
                          />
                        </v-col>

                        <!--Pay Type (Unit)-->
                        <v-col cols="12" sm="12" md="3">
                          <v-autocomplete
                            :items="valueListsStore.valueListItems[18]"
                            label="Pay Type (Units)"
                            placeholder=" "
                            persistent-placeholder
                            item-text="d_name"
                            item-value="d_name"
                            v-model="item.d_incomePeriod"
                            :rules="rules.income_period"
                            clearable
                            clear-icon="fal fa-times-circle"
                          />
                        </v-col>

                        <!--Check Amount-->
                        <v-col cols="12" sm="12" md="2">
                          <v-text-field
                            label="Pay Rate"
                            placeholder=" "
                            persistent-placeholder
                            v-model="item.d_income"
                            :rules="rules.income.concat(rules.only_dollarDigits)"
                          />
                        </v-col>

                        <!--Minimum Salary-->
                        <v-col cols="12" sm="12" md="2">
                          <v-text-field
                            v-model="item.d_minSalary"
                            label="Minimum Salary"
                            placeholder=" "
                            persistent-placeholder
                            :rules="rules.only_dollarDigits"
                          />
                        </v-col>

                        <!--Remove Record-->
                        <v-col
                          v-if="usersStore.authUser.f_delete === 1"
                          cols="12"
                          sm="12"
                          md="1"
                        >
                          <v-btn text icon color="red" @click="item.f_show = 0">
                            <v-icon>fal fa-trash</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Adequate?-->
                        <v-col cols="12" sm="12" md="1">
                          <v-autocomplete
                            :items="valueListsStore.yesNo"
                            item-text="value"
                            item-value="id"
                            v-model="item.f_adequate"
                            label="Adequate?"
                            placeholder=" "
                            persistent-placeholder
                            :rules="rules.adequate"
                          />
                        </v-col>

                        <!--Paid Over 12 Months-->
                        <v-col cols="12" sm="12" md="2">
                          <v-autocomplete
                            :items="valueListsStore.yesNo"
                            item-text="value"
                            item-value="id"
                            v-model="item.f_twelveMonthProrated"
                            label="Paid Over 12 Months"
                            placeholder=" "
                            persistent-placeholder
                            clearable
                            clear-icon="fal fa-times-circle"
                          />
                        </v-col>

                        <!--Note-->
                        <v-col cols="12" sm="12" md="8">
                          <v-text-field
                            label="Note"
                            placeholder=" "
                            persistent-placeholder
                            v-model="item.d_note"
                          />
                        </v-col>

                        <!--Status-->
                        <!--<v-col cols="12" sm="12" md="1">
                          <v-autocomplete
                            :items="valueListsStore.activeStatus"
                            label="Status"
                            placeholder=" "
                            persistent-placeholder
                            item-text="value"
                            item-value="id"
                            v-model="item.f_status"
                          />
                        </v-col>-->
                      </v-row>
                      <v-row dense>
                        <!--Modified/Created-->
                        <v-col cols="12" sm="12" md="5">
                          <span class="text-caption grey--text">
                            Created: {{ $_formatTimestamp(item.created_at) }} &#8226;
                            Modified: {{ $_formatTimestamp(item.updated_at) }}
                            </span>
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-divider />
                      <v-divider />
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { createHelpers, mapMultiRowFields} from "vuex-map-fields";
  import { mixin } from "@/mixins/mixin.js";
  import TitleBar from '@/components/TitleBar';
  import TextDisplay from "@/components/TextDisplay";
  import SalaryCheck from '@/components/SalaryCheck';


  const { mapFields } = createHelpers({
    getterType: "teacherSalaryStore/getEntryField",
    mutationType: "teacherSalaryStore/updateEntryField"
  });

  export default {
    name: "TeacherSalaryEdit",
    mixins: [mixin],
    computed: {
      ...mapState({
        teacherSalaryStore: state => state.teacherSalaryStore,
        usersStore: state => state.usersStore,
        valueListsStore: state => state.valueListsStore
      }),
      ...mapFields(["show", "valid", "mode"]),
      ...mapMultiRowFields("teacherSalaryStore", ["entry.items"])
    },
    components: {
      TitleBar,
      TextDisplay,
      SalaryCheck
    },
    data() {
      return {
        rules: {
          adequate: [v => v === 0 || v === 1 || "Amount Adequacy is required."],
          income: [v => !!v || "Check Amount is required."],
          income_period: [v => !!v || "Check Period is required."],
          only_dollarDigits: [
            v => !v || /^\d+(?:\.\d{1,2})?/.test(v) && /^[^,]+$/.test(v) || "Enter Dollar Amount - Digits and Decimal Only"
          ]
        },
      };
    },
    methods: {
      resetEntry() {
        this.$store.dispatch("teacherSalaryStore/resetEntry");
        if (this.$refs.entryForm) this.$refs.entryForm.resetValidation();
      },

      async openEntry() {
        const teacher = this.$store.getters["teachersStore/getTeacher"];
        const data = {
          fk_teacherID: teacher.id
        };
        await this.$store.dispatch("teacherSalaryStore/entry", data);
        this.show = true;
      },

      addItem() {
        const teacher = this.$store.getters["teachersStore/getTeacher"];
        const data = {
          fk_teacherID: teacher.id
        };
        this.$store.dispatch("teacherSalaryStore/addItem", data);
        if (this.$refs.entryForm) this.$refs.entryForm.resetValidation();
      },

      yearString(yr) {
        if(yr) {
          //regex replace everything except digits and - with empty string
          let result = yr.replace(/[^0-9-]/g,'').trim();
          if(result.length == 4){
            result = parseInt(result.trim());
            result = result.toString() + (result + 1).toString();
            result = [result.slice(0, 4), '-', result.slice(4)].join('');
          }
          return(result);
        }
      },

      cancelEntry() {
        this.show = false;
        this.resetEntry();
      },

      async saveEntry() {
        if (this.$refs.entryForm.validate()) {
          const entry = this.$store.getters["teacherSalaryStore/getEntry"];
          await this.$store.dispatch("teacherSalaryStore/update", entry);

          this.cancelEntry();

           /* Reload List */
          this.$store.dispatch("progressStore/set", true);
          const teacher = this.$store.getters["teachersStore/getTeacher"];
          const data = {
            fk_teacherID: teacher.id
          };
          await this.$store.dispatch('teacherSalaryStore/fetch', data);
          this.$store.dispatch("progressStore/set", false);
        }
      }
    }
  };
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
